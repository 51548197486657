.choices {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 30px;
  row-gap: 20px;
  max-height: 480px;
  overflow-x: scroll;
  padding-bottom: 20px;
}

@media (min-width: 1534px) {
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #1a2d3ea9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2a3c4c;
  }
}

.card {
  flex-basis: 15%;
  min-width: 200px;
  height: 417px;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: rgb(185, 185, 185);
  text-align: center;
  cursor: pointer;
}

.card.ref {
  background-color: #ffffff2b;
}

.about.ref {
  color: transparent;
  user-select: none;
}

.card.selected {
  border: 2px solid #3de2d2;
}

.preview {
  height: 200px;
  display: flex;
  align-items: center;
}

.preview img {
  height: 100%;
}

@media (max-width: 760px) {
  .choices {
    row-gap: 20px;
  }
}

.type-name {
  margin-bottom: 10px;
  min-height: 28px;
}

.class-num {
  color: white;
  margin-bottom: 16px;
  margin-top: 20px;
}

.class-img {
  margin-bottom: 14px;
}
