.topContainer {
    width: 90%;
    margin: auto;
    padding: 15px 0;
    border-bottom: 1px solid #13c3b2;
}

.logo {
    width: 80px;
    margin: auto;
}

.form {
    position: absolute;
    width: 55%;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    font-weight: normal;
    text-align: center;
}

.optionContainer {
    margin: 50px auto;
    padding-left: 30%;
    font-size: 14px;
}
.option {
    display: flex;
    margin-bottom: 20px;
}
.optionContent {
    text-align: left;
    margin-left: 20px;
}

.message {
    font-size: 12px;
}

.formButton {
    background-color: #13c3b2;
    color: white;
    width: 180px;
    border-radius: 5px;
    padding: 3px;
    margin-top: 40px;
}